@import '~@/assets/scss/main.scss';

img {
    padding: 0.125rem;
    background-color: #f5f7fa;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}
